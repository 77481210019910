import 'lazysizes/lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
import "bootstrap";
import "./fontawesome";

import UIBindings from "./ui-bindings";
import { Forms } from "./form";
import { ExternalLinks } from "./externallinks";
import { PageTimer } from "./pagetimer";
import { ShareHandler } from "./share";
import { Geofield } from "./geocomplete";
import { GoogleMap } from "./googlemap";

(new PageTimer()).logToConsole();
(new ExternalLinks('body')).addLinkTarget();
(new ShareHandler()).build();
(new Geofield()).init();


var homepage = !!document.getElementById("map-static");
if (homepage == true) {
    const nmdmapstatic = document.getElementById('map-static');
    const nmdmapanimated = document.getElementById('map-animated');
    nmdmapstatic.addEventListener('click', function() {
        (new GoogleMap()).initialize();
        this.classList.add('d-none');
        nmdmapanimated.classList.remove('d-none');
        nmdmapanimated.classList.add('d-block');
    });
}

const forms = new Forms();
forms.validate();

const UIBinding = new UIBindings();
UIBinding.formSubmitButton();
UIBinding.magnificPopup();
UIBinding.slickCarourel();
UIBinding.bootstrap();
UIBinding.btnAnchor();