import loadGoogleMapsApi from 'load-google-maps-api';
class MapApi {
    
    static loadGoogleMapsApi() {
        return loadGoogleMapsApi({ key: 'AIzaSyDHR_YZMGTEE-uD0O6zTwJnh-JGpweK2xo', libraries: ['places'] });
    }

    static createMap(googleMaps, mapElement, center) {
        return new googleMaps.Map(mapElement, {
            center: center,
            zoom: 14
        });
    }

}
export { MapApi };