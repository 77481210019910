import { library, dom, config } from '@fortawesome/fontawesome-svg-core';
import { faEdit as falEdit, faQuoteLeft as falQuoteLeft, faQuoteRight as falQuoteRight,faCircle as falCircle, faPlay as falPlay } from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch, faCircle as farCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight, faChevronLeft, faChevronDown, faCircle as fasCircle, faSearch as fasSearch, faChevronUp as fasChevronUp, faLink as fasLink, faDownload as fasDownload, faEdit as fasEdit, faSpinner as fasSpinner, faCloudDownload as fasCloudDownload, faChevronCircleRight, faChevronCircleLeft, faAngleLeft as fasAngleLeft, faAngleRight as fasAngleRight, faPaperPlane as fasPaperPlane, faPhoneAlt as fasPhoneAlt, faGlobe as fasGlobe} from '@fortawesome/pro-solid-svg-icons';
import { faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG } from '@fortawesome/free-brands-svg-icons';

config.searchPseudoElements = true;
config.keepOriginalSource = false;

// light
library.add(falEdit, falQuoteLeft, falQuoteRight,falCircle, falPlay);
// regular
library.add(farSearch, farCircle);
// solid
library.add(faChevronCircleRight, faChevronCircleLeft, faChevronRight, faChevronLeft, faChevronDown, fasCircle, fasSearch, fasChevronUp, fasLink, fasDownload, fasEdit, fasSpinner, fasCloudDownload, fasAngleLeft, fasAngleRight, fasPaperPlane, fasPhoneAlt, fasGlobe);
// brands
library.add(faFacebookF, faTwitter, faLinkedinIn, faYoutube, faPinterestP, faGooglePlusG);

dom.i2svg();
dom.watch();