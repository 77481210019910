import { MapApi } from './googlemapapi';
import geocomplete from "geocomplete";

export class Geofield {
    init = () =>  {
        let geoField = $('[data-geo-field]');
        if (!geoField.length > 0) { return; }

        MapApi.loadGoogleMapsApi().then(function() {
             geoField.geocomplete({
                details: ".details",
                detailsAttribute: "data-geo"
            });
        });
    }
}