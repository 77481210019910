import { MapApi } from "./googlemapapi";
export class GoogleMap {
  googleMaps = null;
  prevInfoWindow = null;
  iterator = 0;
  styles = [
    {"elementType": "geometry","stylers": [{"color": "#1d2c4d"}]},
    {"elementType": "labels","stylers": [{"visibility": "off"}]},
    {"elementType": "labels.text.fill","stylers": [{"color": "#8ec3b9"}]},
    {"elementType": "labels.text.stroke","stylers": [{"color": "#1a3646"}]},
    {"featureType": "administrative","elementType": "geometry","stylers": [{"visibility": "off"}]},
    {"featureType": "administrative.country","elementType": "geometry.stroke","stylers": [{"color": "#4b6878"}]},
    {"featureType": "administrative.land_parcel","elementType": "labels.text.fill","stylers": [{"color": "#64779e"}]},
    {"featureType": "administrative.neighborhood","stylers": [{"visibility": "off"}]},
    {"featureType": "administrative.province","elementType": "geometry.stroke","stylers": [{"color": "#4b6878"}]},
    {"featureType": "landscape.man_made","elementType": "geometry.stroke","stylers": [{"color": "#334e87"}]},
    {"featureType": "landscape.natural","elementType": "geometry","stylers": [{"color": "#023e58"}]},
    {"featureType": "poi","stylers": [{"visibility": "off"}]},
    {"featureType": "poi","elementType": "geometry","stylers": [{"color": "#283d6a"}]},
    {"featureType": "poi","elementType": "labels.text.fill","stylers": [{"color": "#6f9ba5"}]},
    {"featureType": "poi","elementType": "labels.text.stroke","stylers": [{"color": "#1d2c4d"}]},
    {"featureType": "poi.park","elementType": "geometry.fill","stylers": [{"color": "#023e58"}]},
    {"featureType": "poi.park","elementType": "labels.text.fill","stylers": [{"color": "#3C7680"}]},
    {"featureType": "road","stylers": [{"visibility": "off"}]},
    {"featureType": "road","elementType": "geometry","stylers": [{"color": "#304a7d"}]},
    {"featureType": "road","elementType": "labels.icon","stylers": [{"visibility": "off"}]},
    {"featureType": "road","elementType": "labels.text.fill","stylers": [{"color": "#98a5be"}]},
    {"featureType": "road","elementType": "labels.text.stroke","stylers": [{"color": "#1d2c4d"}]},
    {"featureType": "road.highway","elementType": "geometry","stylers": [{"color": "#2c6675"}]},
    {"featureType": "road.highway","elementType": "geometry.stroke","stylers": [{"color": "#255763"}]},
    {"featureType": "road.highway","elementType": "labels.text.fill","stylers": [{"color": "#b0d5ce"}]},
    {"featureType": "road.highway","elementType": "labels.text.stroke","stylers": [{"color": "#023e58"}]},
    {"featureType": "transit","stylers": [{"visibility": "off"}]},
    {"featureType": "transit","elementType": "labels.text.fill","stylers": [{"color": "#98a5be"}]},
    {"featureType": "transit","elementType": "labels.text.stroke","stylers": [{"color": "#1d2c4d"}]},
    {"featureType": "transit.line","elementType": "geometry.fill","stylers": [{"color": "#283d6a"}]},
    {"featureType": "transit.station","elementType": "geometry","stylers": [{"color": "#3a4762"}]},
    {"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#0e1626"}]},
    {"featureType": "water", "elementType": "labels.text.fill", "stylers": [{"color": "#4e6d70" }]}
];

  initialize() {
     let mapMarkers = $('[data-map-marker]');
    if (!mapMarkers.length > 0) { return; }

    const me = this;
    MapApi.loadGoogleMapsApi().then(function(googleMaps) {
        me.build(googleMaps, mapMarkers);  
        me.googleMaps = googleMaps; 
    });
  }

  build (googleMaps, mapMarkers) {
    const me = this;
    let features = [];
    let wrapper = $('[data-map-markers]');
    let centerLatLng = new googleMaps.LatLng(wrapper.data('lat'), wrapper.data('lng'));
    let zoom = 4;
    let map = new googleMaps.Map(document.getElementById(wrapper.data('mapId')), {
        zoom: parseInt(zoom),
        center: centerLatLng,
        mapTypeId: googleMaps.MapTypeId.ROADMAP,
        scrollwheel: false, disableDefaultUI: true, navigationControl: false, mapTypeControl: false, scaleControl: false, draggable: true
    });
    
    let iconBase = wrapper.data('mapMarkers');
    let icons = {
        parking: { icon: iconBase + 'hq-icon.png'},
        library: { icon: iconBase + 'hq-icon.png'},
        info: { icon: iconBase + 'hq-icon.png'}
    };

    map.setOptions({styles: this.styles });

    $.each(mapMarkers, function () {
        let marker = { type: 'info' };
        let $marker = $(this);
        let info = $marker.data();
        let content = $marker.html();
        marker.position = new googleMaps.LatLng(info.lat, info.lng);
        marker.title = info.title;
        marker.content = content;
        features.push(marker);
    });
    
    for (let i = 0; i < features.length; i++) {
      setTimeout(function () {
        let marker = me.addMarker(features[me.iterator], map);
        map.panTo(marker.getPosition());
        new googleMaps.event.trigger( marker, 'click' );

        me.iterator = me.iterator + 1;
      }, i * 3000); 
    }
  }

  addMarker(feature, map) {
    const me = this;
    let marker = new me.googleMaps.Marker({ 
      position: feature.position, 
      map: map, 
      title: feature.title,
      animation: me.googleMaps.Animation.DROP,
      draggable: false
    });

    let infoWindow = new me.googleMaps.InfoWindow({ content: feature.content });

    marker.addListener('click', function () {
        if (me.prevInfoWindow) {
            me.prevInfoWindow.close();
        }
        me.prevInfoWindow = infoWindow;
        // infoWindow.open(map, marker);
        
        infoWindow.open({
          anchor: marker,
          map: map,
          shouldFocus: false,
        });
    });

    return marker;
  }
  
}