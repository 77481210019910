import $ from "jquery";
import "magnific-popup";
import "slick-carousel";

export default class UIBindings {

    formSubmitButton() {
        $(document).on('submit', 'form', function () {
            let submitText = $(this).find('[data-submit-text]');
            let submittingText = $(this).find('[data-submitting-text]');
            let btn = submitText.closest('button');
            
            submittingText.removeClass('d-none');
            submitText.addClass('d-none');
            btn.prop('disabled', true);
        });
    }

    magnificPopup () {
        let image = $('[mfp-type="image"]');
        let gallery = $('[mfp-type="gallery"]');
        let video = $('[mfp-type="video"]');

        if (image.length > 0) {
            image.magnificPopup({type: "image"});
        }
        if (gallery.length > 0) {
            gallery.magnificPopup({
                type: 'image',
                closeOnContentClick: false,
                closeBtnInside: true,
                mainClass: 'mfp-with-zoom mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    tCounter: '%curr% of %total%',
                    preload: [0, 2] // Will preload 0 - before current, and 1 after the current image
                },
                zoom: {
                    enabled: true,
                    duration: 500 // don't foget to change the duration also in CSS
                }
            });
        }
        if (video.length > 0) {
            video.magnificPopup({
                type: 'iframe',
                mainClass: 'mfp-fade',
                removalDelay: 160,
                preloader: false,
                fixedContentPos: false,
                closeBtnInside: true,
                iframe:{
                    patterns:{
                        youtube:{
                            src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
                        }
                    }
                }
            });
        }
    }

    slickCarourel() {
        let carousel = $('[data-slick]');
        let customPaging = function(slide, i){
            return $('<span class="cursor-pointer px-1"><i class="fas fa-circle fa-xs slick-dot active"/><i class="far fa-circle fa-xs slick-dot"/></span>');
        }
        if (carousel.length > 0) {
            carousel.slick({customPaging: customPaging});
        }

        $('.slick-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.slider-nav'
          }).on('afterChange',function(e,o){
            //on change slide = do action
            $('iframe').each(function(){
              $(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');    
            });
        });
        $('.slider-nav').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.slick-for',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            prevArrow: '<span class="text-primary position-absolute slick-arrow zindex-1 left-0 cursor-pointer px-1"><i class="fas fa-chevron-circle-left"/></span>',
            nextArrow: '<span class="text-primary position-absolute slick-arrow zindex-1 right-0 cursor-pointer px-1"><i class="fas fa-chevron-circle-right"/></span>',
        });
    }

    bootstrap () {
    	$('[data-toggle="tooltip"]').tooltip()
    }

    btnAnchor(){
        $('.anchor').click(function() {
          var anchor = $(this).data('target');
          var anchorTarget =  $(anchor);
          console.log(anchorTarget.length, 'click', anchor);
          if (anchorTarget.length) {
             $('html, body').animate({
                scrollTop: anchorTarget.offset().top - 110
            }, 1000);
            return false;
          };
           
        });
    }

}